body {
  height: 100%;
  width: 100%;
  background-color: lightgray;
  justify-content: center;
  display: flex;
}
.App {
  max-width: 800px;
}
